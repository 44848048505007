<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5455 18.6987C11.2907 22.6273 6.1676 19.3403 2.88817 17.3439C2.68524 17.2181 2.34033 17.3733 2.63959 17.7171C3.73213 19.0418 7.31262 22.2348 11.9862 22.2348C16.663 22.2348 19.4453 19.6829 19.7933 19.2377C20.139 18.7963 19.8948 18.5529 19.5454 18.6987H19.5455ZM21.8639 17.4184C21.6422 17.1297 20.5159 17.0759 19.8071 17.1629C19.0972 17.2475 18.0317 17.6814 18.1243 17.9419C18.1718 18.0395 18.2689 17.9957 18.7565 17.9518C19.2456 17.903 20.6155 17.7302 20.9009 18.1033C21.1877 18.479 20.464 20.2689 20.3318 20.5576C20.2041 20.8463 20.3806 20.9207 20.6205 20.7285C20.8571 20.5363 21.2854 20.0386 21.5728 19.3342C21.8582 18.626 22.0324 17.6381 21.8639 17.4184Z"
      fill="#FF9900"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9951 10.682C13.9951 11.7128 14.0211 12.5724 13.5001 13.4878C13.0796 14.2322 12.4135 14.6899 11.6692 14.6899C10.6532 14.6899 10.0615 13.9158 10.0615 12.7734C10.0615 10.5182 12.0822 10.1089 13.9951 10.1089V10.682ZM16.6633 17.1312C16.4884 17.2874 16.2353 17.2986 16.0381 17.1944C15.1599 16.465 15.0035 16.1264 14.5198 15.4305C13.0685 16.9116 12.0413 17.3544 10.1583 17.3544C7.93293 17.3544 6.19873 15.9812 6.19873 13.2311C6.19873 11.0839 7.3636 9.62135 9.01951 8.9069C10.456 8.27418 12.4619 8.16256 13.9951 7.98771V7.64531C13.9951 7.01636 14.0434 6.27211 13.675 5.72882C13.3512 5.2413 12.7335 5.04033 12.1901 5.04033C11.1817 5.04033 10.2811 5.55758 10.0615 6.62933C10.0168 6.86756 9.84197 7.10202 9.60382 7.11316L7.03602 6.83783C6.82023 6.78935 6.58208 6.61451 6.64161 6.28324C7.2333 3.17223 10.0429 2.23438 12.5585 2.23438C13.8461 2.23438 15.5282 2.57677 16.5441 3.55181C17.8318 4.75378 17.7089 6.35769 17.7089 8.10302V12.2263C17.7089 13.4656 18.2225 14.0089 18.7062 14.6787C18.8774 14.9169 18.9147 15.2035 18.6988 15.3821C18.1592 15.8323 17.1991 16.6697 16.6707 17.1386L16.6632 17.1312"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: 'ic-amazon-letter-logo',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
